import React from "react";
import theme from "./helpers/theme";
import Profiles from "./Profiles";

const Footer = ({showProfiles=false}) => {
  return (
    <>
      <style>{`
        .sectionBody.footerSectionBody {
          background-color: var(--headFootBgBase2);
          /*background-image:
            linear-gradient(45deg, var(--headFootBgBase) 25%, transparent 25%),
            linear-gradient(-45deg, var(--headFootBgBase) 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, var(--headFootBgBase) 75%),
            linear-gradient(-45deg, transparent 75%, var(--headFootBgBase) 75%);
          */
          background-image: repeating-linear-gradient(-45deg, var(--headFootBgBase), var(--headFootBgBase) 1px, transparent 1px, transparent 6px);
          background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
          background-size: 4px 4px;
          box-sizing: border-box;
          position: relative;
        }
        .sectionBody .footerSectionInner {
          /* height: var(--sectionHeight); */
        }
        .footerSectionBody {
          background-color: ${theme.colors.monoDark};
          color: #ffffff;
        }
        .footerSectionBody a,
        .footerSectionBody a:visited {
          color: rgba(131,166,174,0.9);
          text-decoration: none;
        }
        .footerSectionBody a:hover {
          color: rgba(131,166,174,0.9);
          text-decoration: underline;
        }
        .copyrightBar {
          align-items: center;
          display: flex;
          flex-direction: row;
          font-size: 12px;
          justify-content: center;
          line-height: 1.2;
          padding: 6px 20px 6px 20px;
          white-space: nowrap;
        }
        .copyrightBar > .copyrightText {
          display: contents;
          white-space: nowrap;
        }
        @media screen and (max-width: ${theme.responsive.phone}) {
          .copyrightBar {
            flex-direction: column;
            font-size: 10px;
          }
          .copyrightBar > .copyrightText {
            display: inline;
          }
        }
      `}</style>
      <div className="sectionBody footerSectionBody">
        <div className="contentWidth footerSectionInner">
          {!!showProfiles &&
            <Profiles />
          }
          <div className="copyrightBar">
            <span className="copyrightText">Powered by BI3 Inisghts, LLC.</span>
            <span className="nophone"> &nbsp; &bull; &nbsp; </span>
            <span className="copyrightText">&copy; Copyright BI3 Inisghts. All rights reserved.</span>
            <span className="nophone"> &nbsp; &bull; &nbsp; </span>
            <span className="copyrightText"><a href="/privacypolicy">Privacy Policy</a></span>
            <span className="nophone"> &nbsp; &bull; &nbsp; </span>
            <span className="copyrightText"><a href="/termsofservice">Terms & Consitions</a></span>
          </div>
        </div>
      </div>
    </>
  )
};

export default Footer;
