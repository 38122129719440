import "./fonts/Vegur.otf";
import "./fonts/VegurBold.otf";
import "./fonts/VegurLight.otf";
import "./App.css";
import Main from "./app/Main";

function App() {
  return (
    <Main style={{display:"flex",flexDirection:"column",flexGrow:1}}></Main>
  );
}

export default App;
