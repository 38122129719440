import React from "react";
import theme from "./helpers/theme";
import garrett from "./images/headshot_garrett.jpg";
import ben from "./images/headshot_ben.jpg";
import michael from "./images/headshot_michael.jpg";

const Profiles = () => {
  return (
    <>
      <style>{`
        .profilesContent {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: calc(20px + 2vw)
        }
        .profilesContent .title {
          font-size: calc(24px + 1vw);
          line-height: calc(24px + 1.2vw);
        }
        .profilesWrapper {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-evenly;
        }
        .profileContainer {
          align-items: center;
          flex-basis: 0;
          flex-direction: column;
          flex-grow: 1;
          flex-shrink: 1;
          justify-content: flex-start;
          margin: 20px 20px 20px 20px;
          max-width: 380px;
          position: relative;
        }
        .profilePictureWrapper {
          display: block;
          position: relative;
          width: 100%;
        }
        .profilePictureWrapper:before {
          content: " ";
          display: block;
          padding-top: 100%; /* trick initial ratio of 1:1*/
        }
        .profilePicture {
          border-radius: 50%;
          border: 6px solid #ffffff;
          height: 100%;
          left: 0;
          object-fit: cover;
          overflow: hidden;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .profileDesc {
          line-height: 20px;
          margin-top: 30px;
          padding: 10px;
        }
        .profileTitle {
          font-weight: 600;
          padding-bottom: 8px;
        }
        @media screen and (max-width: ${theme.responsive.tablet}) {
          .profilesWrapper {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
          }
          .profileContainer {
            padding-top: 60px;
          }
          .profileContainer:before {
            background-color: ${theme.colors.white};
            content: ' ';
            display: block;
            height: 1px;
            position: absolute;
            top: 0px;
            width: 100%;
          }
          .profileContainer:first-child {
            padding-top: 0;
          }
          .profileContainer:first-child:before {
            display: none;
          }
        }
      `}</style>
          <div className="profilesContent">

            <div className="title">Who We Are</div>

            <div className="profilesWrapper garrett">
              <div className="profileContainer">
                <div className="profilePictureWrapper"><img className="profilePicture" src={garrett} /></div>
                <div className="profileDesc">
                <div className="profileTitle">Garrett Bach</div>
                I am a seasoned SaaS Software developer with over two decades experience in building management solutions. I bring deep experience in simplifying and digitizing complex workflows, and visualizing data to be meaningful and actionable. I have a bachelor's degree in Computer Science. I have supported multiple startups through small and large growth phases and acquisitions. I build on the latest technology industry standards and data securities. Now, my whole focus is on VisInvo, partnering with you to take your build management solution to the next level.</div>
              </div>
              <div className="profileContainer ben">
                <div className="profilePictureWrapper"><img className="profilePicture" src={ben} /></div>
                {/* <div className="profileDesc">Ben Daniel - CEO<br />
                I have countless years of construction experience, from million-dollar high-end custom homes to billion-dollar heavy civil projects. I bring a combination of hands-on experience from the field with technical knowledge gained through construction estimating in the office. My focuses have been on efficiently managing pre-construction and bid process, aiding project teams in compiling sound budgets, and optimizing project resources to complete project under budget. Like many of you, I tried solving management problems with custom excel sheets, and I built a 'secret sauce' which became the foundational database for VisInvo. I'm proud to be sharing this with you.</div> */}
                <div className="profileDesc">
                <div className="profileTitle">Benjamin Daniel</div>
                I have over 15 years of construction experience including billion-dollar heavy civil projects, million-dollar high-end custom homes, and numerous residential projects. With my bachelor's degree in Construction Management, I bring a combination of hands-on experience from the field and technical knowledge gained through construction estimating in the office. My focuses have been on efficiently managing pre-construction and bid processes, aiding project teams in compiling sound budgets, and optimizing project resources to complete projects under budget. My unique experiences have provided insight into the common construction problems plaguing the industry. I was instrumental in building the first version of VisInvo based on the Excel platform, which organized and streamlined the construction process.</div>
              </div>
              {/* <div className="profileContainer michael">
                <div className="profilePictureWrapper"><img className="profilePicture" src={michael} /></div>
                <div className="profileDesc">
                <div className="profileTitle">Michael Horn</div>
                I am a retired custom builder business owner with over 20 years experience in residential customs and commercial TI's. I have spent several years in the SAAS Construction project management space. My passion is organizational psychology, operations audits and conflict resolution strategies.</div>
              </div> */}
            </div>

          </div>
    </>
  )
};

export default Profiles;
