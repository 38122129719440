import React from "react";
import theme from "./helpers/theme";
import blueprint_bg from "./images/blueprint_med.jpg";
import wf1_project_summary from "./images/wf_project_summary.jpg";
import wf2_scope_edit from "./images/wf_scope_edit.jpg";
import wf3_scope_items from "./images/wf_scope_items.jpg";
import wf4_scope_agreements from "./images/wf_scope_agreements.jpg";

const ProjectWorkflow = () => {
  return (
    <>
      <style>{`
        .sectionBody.workflowSectionBody {
          padding: 20px;
          position: relative;
        }
        .sectionBody.workflowSectionBody:before {
          background-image: url(${blueprint_bg});
          background-position: 50% 0;
          background-repeat: no-repeat;
          background-size: cover;
          content: ' ';
          display: block;
          height: 100%;
          left: 0;
          opacity: 0.2;
          position: absolute;
          top: 0;
          width: 100%;
        }
        .sectionBody .workflowSectionInner {
          position: relative;
        }

        .workflowContainer {
          justify-content: center;
          display: flex;
          flex-direction: row;
          margin: 130px 0;
          padding-top: 130px;
          position: relative;
        }
        .workflowContainer:before {
          background-color: ${theme.colors.monoMedium};
          content: ' ';
          display: block;
          height: 1px;
          position: absolute;
          top: 0px;
          width: 80%;
        }
        .workflowContainer:first-child {
          padding-top: 0;
        }
        .workflowContainer:first-child:before {
          display: none;
        }
        .wf_img_wrapper {
          align-items: flex-start;
          justify-content: flex-end;
          display: flex;
          flex-direction: row;
          margin: 10px 0;
          width: 50%;
        }
        .wf_image {
          border-radius: 3px;
          box-shadow: 1px 2px 6px 1px rgba(0,0,0,0.4);
          display: block;
          margin: 0 20px;
          max-width: 70%;
        }
        .wf_desc_wrapper {
          align-items: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin: 0 20px;
          width: 50%;
        }
        .wf_title {
          color: ${theme.colors.base};
          font-size: calc(12px + 2vw);
          font-weight: bold;
          line-height: 1.6em;
        }
        .wf_desc {
          color: ${theme.colors.base};
          font-size: calc(12px + 1vw);
          line-height: 1.2em;
        }

        @media screen and (max-width: ${theme.responsive.tablet}) {
          .workflowContainer {
            align-items: center;
            flex-direction: column;
            margin: 100px 0;
            padding-top: 100px;
          }
          .wf_img_wrapper, .wf_desc_wrapper {
            justify-content: center;
            width: auto;
          }
          .wf_desc_wrapper {
            padding-top: 50px;
          }
        }
        @media screen and (min-width: ${theme.responsive.tablet}) { /* Anything BIGGER than tablet, lock size */
          .wf_title {
            font-size: 32px;
          }
          .wf_desc {
            font-size: 22px;
            width: 75%;
          }
        }
      `}</style>
      <div className="sectionBody workflowSectionBody">
        <div className="contentWidth workflowSectionInner">
          <div className="workflowSection">
            <div className="workflowContainer wf1">
              <div className="wf_img_wrapper">
                <img className="wf_image" src={wf1_project_summary} />
              </div>
              <div className="wf_desc_wrapper">
                <div className="wf_title">Planning - Project Summary</div>
                <div className="wf_desc">Your Project Summary screen rolls up all your scopes with essential details showing your current status in progress, billing, revenue, and more. Many details are customizable to fit your preferences. Be alerted if a Subcontractor has not been given or hasn't agreed to the latest version of the project plan documents. All of those agreements, and a history of changes, are all conveniently available for viewing.</div>
              </div>
            </div>
            <div className="workflowContainer wf2">
              <div className="wf_img_wrapper">
                <img className="wf_image" src={wf2_scope_edit} />
              </div>
              <div className="wf_desc_wrapper">
                <div className="wf_title">Estimating - Scope Sheet Items</div>
                <div className="wf_desc">Creating your internal projections and bid forms for subcontracts couldn't be easier or faster than what VisInvo provides. We have an extensive database prepoluated with every conceivable line item. Did we miss one? Add custom items, or update your database with all your custom items so they are consisten across all your jobs.</div>
              </div>
            </div>
            <div className="workflowContainer wf3">
              <div className="wf_img_wrapper">
                <img className="wf_image" src={wf3_scope_items} />
              </div>
              <div className="wf_desc_wrapper">
                <div className="wf_title">Bidding - Subcontractor Entry</div>
                <div className="wf_desc">Your Subcontractors are sent a secure link to enter their bid details online. This way you (hopefully) never have to do manual entry again! If you have that sub who refuses modern technology (it happens!) you have dynmaic PDFs which you can email or print for them. View your bid comparison: potential mistakes or missing data is highlighted in yellow, and heatmap line-item costs: Lowest bids are in green, Highest in red. Select your preferred subcontractor with confidence.</div>
              </div>
            </div>
            <div className="workflowContainer wf4">
              <div className="wf_img_wrapper">
                <img className="wf_image" src={wf4_scope_agreements} />
              </div>
              <div className="wf_desc_wrapper">
                <div className="wf_title">Contract - Requirements & Exclusions</div>
                <div className="wf_desc">You shouldn't need a law degree to write simple but detailed agreements. Choose from your pre-configured requirements and exclusions, or add unique requirements specific to this job. With multiple categories to cover all apsects of the project that often get forgotten until it's too late, here you can be assured that every 'i' is dotted and ever 't' is crossed. You didn't have to work on Saturday or hire an assistent (or a lawyer) to get it done right. You and your Subcontractor will agree - there's never been an easier system to manage these details while saving valuable time.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default ProjectWorkflow;
