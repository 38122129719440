import React from "react";
import theme from "./helpers/theme";
import Header from "./Header";
import Landing1 from "./LandingHousePlanVisinvo";
import ProjectWorkflow from "./ProjectWorkflow";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import SharedStyles from "./helpers/SharedStyles";

const Main = () => {
  if (window.location.pathname.toLowerCase()==="/privacypolicy") {
    document.title = "Privacy Policy - VisInvo.com";
    return (<PrivacyPolicy />)
  }
  if (window.location.pathname.toLowerCase()==="/termsofservice") {
    document.title = "Terms and Conditions - VisInvo.com";
    return (<TermsOfService />)
  }
  document.title = "VisInvo.com";
  return (
    <>
      <style>{`
        html {
          --headFootBgBase: rgb(50,55,60);
          --headFootBgBase2: rgb(60,65,70);
          background-color: var(--headFootBgBase);
          box-sizing: border-box;
          display: block;
          margin: 0;
          padding: 0;
          position: relative;
        }
        body {
          align-items: stretch;
          background-color: var(--headFootBgBase);
          box-sizing: border-box;
          color: rgba(0,0,0,0.75);
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          font-family: 'Vegur', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
          justify-content: flex-start;
          line-height: 18px;
          margin: 0;
          padding: 0;
        }
        #root {
          box-sizing: border-box;
          align-items: center;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          justify-content: flex-start;
        }

      `}</style>
      <SharedStyles />
      <Header showActions={true} />
      <Landing1 />
      <ProjectWorkflow />
      <Footer showProfiles={true} />
    </>
  )
};

export default Main;
