import React from "react";
import theme from "./helpers/theme";
import logo from "./images/visinvo_logo.svg";

const Header = ({showActions=false}) => {
  return (
    <>
      <style>
        {`
          .flexHeader {
            --headerMultiple: 1;
          }
          @media screen and (max-width: ${theme.responsive.phone}) {
            .flexHeader {
              --headerMultiple: 0.65;
            }
          }
          .headerSpacer {
            display: flex;
            flex-basis: calc(110px * var(--headerMultiple));
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
          }
          .contentHeader {
            align-items: center;
            align-self: stretch;
            background-color: var(--headFootBgBase2);
            /*background-image:
              linear-gradient(45deg, var(--headFootBgBase) 25%, transparent 25%),
              linear-gradient(-45deg, var(--headFootBgBase) 25%, transparent 25%),
              linear-gradient(45deg, transparent 75%, var(--headFootBgBase) 75%),
              linear-gradient(-45deg, transparent 75%, var(--headFootBgBase) 75%);
            */
            background-image: repeating-linear-gradient(-45deg, var(--headFootBgBase), var(--headFootBgBase) 1px, transparent 1px, transparent 6px);
            background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
            background-size: 4px 4px;
            box-shadow: 0px 3px 6px rgba(60,65,70,0.65);
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex-grow: 0;
            flex-shrink: 0;
            height: calc(110px * var(--headerMultiple));
            justify-content: stretch;
            margin-left: -10px;
            margin-right: -10px;
            padding: 0 30px;
            position: fixed;
            width: calc(100% + 20px);
            z-index: 3;
          }
          @media print {
            .contentHeader {
              box-shadow: none;
            }
          }
          .contentHeader .contentWidth {
            flex-grow: 1;
            justify-content: center;
            position: relative;
          }

          .contentWrapper {
            align-items: flex-start;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: space-between;
          }

          .leftSpacer {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: flex-start;
            width: 35%;
          }
          @media screen and (max-width: ${theme.responsive.tiny}) {
            .leftSpacer {
              display: none;
            }
          }

          .titlebarTitle {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: center;
          }
          a.titlebarTitle:hover {
            text-decoration: none;
          }
          .titleLogo {
            height: 65px;
            margin-right: 20px;
          }
          .titleText {
            align-items: flex-start;
            color: ${theme.colors.baseMedium};
            display: flex;
            flex-direction: column;
            font-family: VegurBold;
            font-size: 54px;
            justify-content: center;
            line-height: 1.2em;
            margin: 0;
            padding: 0;
          }
          @media screen and (max-width: ${theme.responsive.phone}) {
            .titleLogo {
              height: 42px;
              margin-right: 10px;
            }
            .titleText {
              font-size: 32px;
            }
          }

          .loginContainer {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: flex-end;
            width: 35%;
          }
          .headerSection .loginLink {
            margin-left: 20px;
          }
          .demoButton.actionButton {
            text-align: center;
            width: 130px;
            white-space: nowrap;
          }

          @media screen and (max-width: ${theme.responsive.phone}) {
            .demoButton.actionButton {
              font-size: 14px;
              padding: 0 2vw;
              width: 90px;
            }
            .loginLink {
              display: none;
            }
          }
          @media screen and (max-width: ${theme.responsive.tiny}) {
            .loginContainer {
              width: auto;
            }
            .demoButton.actionButton {
              font-size: 14px;
              padding: 0 4vw;
            }
            .loginLink {
              display: none;
            }
          }
        `}
      </style>
      <div className="contentHeader flexHeader">
        <div className="contentWidth">
          <div className="contentWrapper headerSection">

            <div className="leftSpacer">
              {!!showActions &&
                <a className="actionButton demoButton" href={process.env.REACT_APP_REGISTER_URL}>SIGN UP!</a>
              }
            </div>

            <a className="titlebarTitle" href="/">
              <img src={logo} className="titleLogo"></img>
              <div className="titleText">VisInvo</div>
            </a>

            <div className="loginContainer">
              {!!showActions &&
                <>
                  <a className="actionButton demoButton" href="mailto:demo@visinvo.com?subject=VisInvo Demo Request&body=Hello,%0D%0A%0D%0AMy name is ____. I'd love to request a demo. Please call me at ___-___-____.%0D%0A%0D%0AI look forward to hearing from you.%0D%0A%0D%0A%0D%0A">Request <span className="nophone">a </span>Demo</a>
                  <a className="primaryButton loginLink nophone" href="https://app.visinvo.com">Login</a>
                </>
              }
            </div>

          </div>
        </div>
      </div>
      <div className="headerSpacer flexHeader"></div>
    </>
  )
};

export default Header;
