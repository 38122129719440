const theme = () => {

  const primaryHslBlue = "200";
  // let primaryHslPurple = "280";
  // let primaryHslTeal = "190";
  // let primaryHslGray = "0";

  const primaryHslBase = primaryHslBlue;
  // if (!!main.theme && main.theme === "purple") {
  //   primaryHslBase = primaryHslPurple;
  // } else if (!!main.theme && main.theme === "teal") {
  //   primaryHslBase = primaryHslTeal;
  // } else if (!!main.theme && main.theme === "gray") {
  //   primaryHslBase = primaryHslGray;
  // }

  const primaryHsl = {
    base:                 `hsl(${primaryHslBase}, 80%, 30%)`,   // 30%  // #0f618a
    baseHover:            `hsl(${primaryHslBase}, 81%, 45%)`,   // 45%  // #1692d0
    baseMedium:           `hsl(${primaryHslBase}, 62%, 61%)`,   // 50%  // #5fb1da
    baseLight:            `hsl(${primaryHslBase}, 81%, 75%)`,   // 55%  // #8cd0f3
    baseLightHover:       `hsl(${primaryHslBase}, 73%, 82%)`,   // 75%  // #b0e0f3
    baseBackground:       `hsl(${primaryHslBase}, 84%, 97%)`,   // 97%  // #f1f9fe
    baseBackgroundHover:  `hsl(${primaryHslBase}, 98%, 95%)`,   // 97%  // #e6f6ff
    // Special use colors
    divBarBg:             `hsl(${primaryHslBase}, 40%, 59%)`,   //  "#6da5c1",
    subdivBarBg:          `hsl(${primaryHslBase}, 40%, 65%)`,   //  "#82b1c9",
  };

  const colors = {
    // Primary
    ...primaryHsl,

    // Ancillary
    accent: "#ffa800",
    accentHover: "#ffc34d",
    accentBackground: "#fffaf0",   // "#fff9f2",
    accentHighlightThin: "hsl(40, 100%, 93%)",  // "#ffedc9",
    accentHighlight: "hsl(40, 100%, 89%)",  // "#ffedc9",
    // Cautionary
    caution: "#ff5000",
    cautionHover: "#ff9666",
    cautionBackground: "#fff7f5",
    cautionHighlightThin: "hsl(0, 100%, 96%)",  // "#ffd4d4",
    cautionHighlight: "hsl(0, 100%, 92%)",  // "#ffd4d4",
    // Success
    success: "#6BAA55",
    successDark: "#48962d",
    successHover: "#98c388",
    successLight: "#abda9b",
    successBackground: "#f6faf5",
    successHighlightThin: "hsl(104, 52%, 92%)",
    successHighlight: "hsl(104, 52%, 87%)",// "#d6efcd",
    newRowBg: "#e5ffe8",
    selectedBackground: "#e0e6dc",
    selectedHighBackground: "#e4f5e8",
    // Monochromatic
    monoBlack: "#1a1a1a",
    monoDark: "#444444",
    monoMedium: "#767676",
    monoLight: "#bfbfbf",
    monoDisabled: "#dadada",
    monoInputDisabled: "#eaebec",
    monoBackground: "#f2f2f2",
    // Placeholder
    placeholder: "#d0d4dc",  //"#d0d4dc" from AutoComplete
    listRowDiv: "#cad4d8",

    white: "#ffffff",
  };

  const sizes = {
    icon: {
      small: 16,
      default: 18,
      large: 22,
      xlarge: 26,
    }
  }

  return {
    responsive: {
      tiny: "500px",
      phone: "812px",
      tablet: "1024px",
      screenMedium: "1300px",
      screenLarge: "1800px",
    },

    colors: colors,

    sizes: sizes,

    borderRadius: "3px",

  };

};

export default theme();
