import React from "react";
import LegalStyles from "./helpers/LegalStyles";
import SharedStyles from "./helpers/SharedStyles";
import Header from "./Header";
import Footer from "./Footer";

const PrivacyPolicy = () => {
	return (
		<>
			<style>
				{`
				html {
					--headFootBgBase: rgb(255,255,255);
					--headFootBgBase2: rgb(255,255,255);
					background-color: var(--headFootBgBase);
					box-sizing: border-box;
					display: block;
					margin: 0;
					padding: 0;
					position: relative;
				}
				body {
					align-items: stretch;
					background-color: var(--headFootBgBase);
					box-sizing: border-box;
					color: rgba(0,0,0,0.75);
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					font-family: 'Vegur', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-size: 16px;
					justify-content: flex-start;
					line-height: 18px;
					margin: 0;
					padding: 0;
				}
				#root {
					box-sizing: border-box;
					align-items: center;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					justify-content: flex-start;
				}
				`}
			</style>
			<Header />
			<div className="legalPages">
				<SharedStyles />
				<LegalStyles />
				<div className="sectionBody">
					<div className="contentWidth workflowSectionInner">
						<div className="workflowSection">
							<div className="workflowContainer">

								<div className="par_title">Privacy Policy</div>

								<div className="par">"VisInvo" ("us", "we", or "our") operates http://www.visinvo.com, http://app.visinvo.com, and any other sub-domains of 'visinvo.com' (otherwise referred to as the "Site", "Sites", "Product", "Products", "Service", or "Services"). This page informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from users of the Site. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy</div>



								<div className="par_subtitle">Information Collection And Use</div>

								<div className="par">While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name ("Personal Information").</div>
								<div className="par">We may also collect other information that you or others may provide to us, including the content that you or others submit, post to, or create within the Solution, such as photos, comments, reviews, searches, photo notes, discussions, project details and costs, and style preferences.</div>
								<div className="par">We may record, monitor, or otherwise collect details about your communications when you communicate with us (via email, phone, through the Solution, or otherwise) and when you use our software to communicate with others. If required by applicable law, we will provide you notice or obtain consent before recording or monitoring any of these calls.</div>



								<div className="par_subtitle">Information Collection And Use</div>

								<div className="par">While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to your name, email(s), phone number(s), address(es),  ("Personal Information").</div>



								<div className="par_subtitle">Log Data</div>

								<div className="par">Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics. In addition, we may use third party services to help us collect, monitor, and analyze this data so that we can be aware of issues, improve high-traffic page performance, and other benefits to improve our business and products, and improve the experience of our customers.</div>



								<div className="par_subtitle">Security</div>

								<div className="par">The security of your Personal Information is important to us. While we strive to use commercially acceptable means to protect your Personal Information. We implement industry best-practices and processes along with technical measures to protect Personal Data covered by this Policy against unauthorized access, destruction, loss, alteration or misuse. However, no method of transmission over the Internet, or method of electronic storage, can be guaranteed as 100% secure.</div>



								<div className="par_subtitle">Communication</div>

								<div className="par">We may use your Personal Information to contact you with newsletters, marketing, or promotional materials and other information. Paying customers should expect regular electronic communication as part of their business, and as part of the services they are subscribing to use.</div>



								<div className="par_subtitle">Cookies</div>
								<div className="par">Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to and from your browser installed on your computer or device, and are sent to and from a web site. Like many sites, we use "cookies" to collect site usage data. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Sites.</div>



								<div className="par_subtitle">Policy Updates</div>

								<div className="par">We reserve the right to update or change this Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</div>


								<div className="par_date">Last updated: February 17, 2023</div>

							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		</>
	)
};

export default PrivacyPolicy;
