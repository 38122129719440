import React from "react";
import theme from "./theme";

const LegalStyles = ()=>{
	return (
		<style>
			{`
				html {
					--headFootBgBase: rgb(255,255,255);
					--headFootBgBase2: rgb(255,255,255);
					background-color: var(--headFootBgBase);
					box-sizing: border-box;
					display: block;
					margin: 0;
					padding: 0;
					position: relative;
				}
				body {
					align-items: stretch;
					background-color: var(--headFootBgBase);
					box-sizing: border-box;
					color: rgba(0,0,0,0.75);
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					font-family: 'Vegur', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
					-moz-osx-font-smoothing: grayscale;
					-webkit-font-smoothing: antialiased;
					font-size: 16px;
					justify-content: flex-start;
					line-height: 18px;
					margin: 0;
					padding: 0;
				}
				#root {
					box-sizing: border-box;
					align-items: center;
					display: flex;
					flex-direction: column;
					flex-grow: 1;
					justify-content: flex-start;
				}

				.sectionBody {
					align-items: center;
					display: flex;
					flex-direction: column;
					font-family: Vegur;
					justify-content: flex-start;
					padding: 20px;
					position: relative;
				}
				.sectionBody .workflowSectionInner {
					max-width: 900px;
					position: relative;
				}

				.workflowContainer {
					align-items: flex-start;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					position: relative;
				}
				.par_wrapper {
					align-items: flex-start;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					margin: 0 20px;
				}
				.par_title {
					color: ${theme.colors.base};
					font-size: calc(12px + 2vw);
					font-weight: bold;
					line-height: 1.6em;
					padding-top: 20px;
				}
				.par_subtitle {
					color: ${theme.colors.base};
					font-size: calc(12px + 1.6vw);
					font-weight: bold;
					line-height: 1.4em;
					padding-top: 50px;
				}
				.par {
					color: ${theme.colors.base};
					font-size: calc(12px + 1vw);
					font-weight: normal;
					line-height: 1.2em;
					padding-top: 30px;
				}
				.par_date {
					color: ${theme.colors.base};
					font-size: calc(8px + 1vw);
					font-weight: normal;
					line-height: 1.4em;
					padding-top: 30px;
				}

				@media screen and (min-width: ${theme.responsive.tablet}) {  /* Anything BIGGER than tablet, lock size */
					.par_title {
						font-size: 30px;
					}
					.par_subtitle {
						font-size: 22px;
					}
					.par {
						font-size: 18px;
					}
					.par_date {
						font-size: 14px;
					}
				}
				@media print {
					.contentHeader {
						height: 80px;
						position: relative;
					}
					.titleLogo {
						height: 38px;
						margin-right: 10px;
					}
					.titleText {
						font-size: 30px;
					}
					.headerSpacer {
						display: none;
					}
					.par_title {
						font-size: 20px;
						padding-top: 10px;
						padding-bottom: 0px;
					}
					.par_subtitle {
						font-size: 16px;
						padding-top: 20px;
						padding-bottom: 0px;
					}
					.par {
						font-size: 12px;
						padding-top: 20px;
						padding-bottom: 0px;
					}
					.par_date {
						font-size: 10px;
					}
				}



				ol {
					list-style-type: none;
					counter-reset: item;
					margin: 0;
					padding: 0;
				}

				ol > li {
					display: table;
					counter-increment: item;
					margin-bottom: 0.6em;
				}

				ol > li:before {
					content: counters(item, ".") ". ";
					display: table-cell;
					padding-right: 0.6em;
				}

				li ol > li {
					margin: 0;
				}

				li ol > li:before {
					content: counters(item, ".") " ";
				}

				.legalPages .footerSectionBody {
					color: ${theme.colors.base};
				}
			`}
		</style>
		// https://stackoverflow.com/a/26245056  source of ideas for ol, li styles for decimal-numbering of numbered lists.

	);
};

export default LegalStyles;
