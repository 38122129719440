import React from "react";
import theme from "./theme";

const SharedStyles = ()=>{
	return (
		<style>{`
			.sectionBody {
				background-color: #ffffff;
				box-sizing: border-box;
				align-items: center;
				align-self: stretch;
				display: flex;
				flex-direction: column;
				justify-content: stretch;
				flex-grow: 1;  /* grow to available screen height */
				z-index: 2;
			}
			.contentFooter {
				align-items: center;
				align-self: stretch;
				display: flex;
				flex-direction: column;
				justify-content: stretch;
				z-index: 1;
			}
			.contentWidth {
				box-sizing: border-box;
				display: flex;
				flex-direction: column;
				max-width: 1800px;
				overflow: hidden;
				margin: 0 60px;
				width: 100%;
			}
			a, a:hover, a:visited {
				color: rgba(31,66,74,0.9);
				text-decoration: none;
			}
			a:hover {
				text-decoration: underline;
			}
			a.actionButton:hover,
			a.primaryButton:hover {
				text-decoration: none;
			}

			.primaryButton,
			.primaryButton:visited {
				align-items: center;
				background-color: ${theme.colors.base};
				border: 1px solid ${theme.colors.divBarBg};
				border-radius: ${theme.borderRadius};
				box-shadow: 0px 3px 5px rgba(0,0,0,0.95);
				color: ${theme.colors.monoBackground};
				flex-direction: row;
				font-weight: bold;
				height: 30px;
				justify-content: center;
				line-height: 30px;
				padding-left: calc(10px + 0.6vw);
				padding-right: 1vw;
				text-decoration: none;
				width: auto;
			}
			.primaryButton:hover {
				box-shadow: 0px 3px 7px rgba(0,0,0,1);
				color: ${theme.colors.baseLight};
				outline: none;
			}
			.primaryButton:active {
				box-shadow: 0px 1px 2px rgba(0,0,0,1);
			}
			.secondaryButton,
			.secondaryButton:visited {
				align-items: center;
				background-color: ${theme.colors.placeholder};
				border: 1px solid ${theme.colors.monoBackground};
				border-radius: ${theme.borderRadius};
				box-shadow: 0px 3px 5px rgba(0,0,0,0.95);
				color: ${theme.colors.base};
				flex-direction: row;
				font-weight: bold;
				height: 30px;
				justify-content: center;
				line-height: 30px;
				padding-left: calc(10px + 0.6vw);
				padding-right: 1vw;
				text-decoration: none;
				width: auto;
			}
			.secondaryButton:hover {
				box-shadow: 0px 3px 6px rgba(0,0,0,1);
				color: ${theme.colors.divBarBg};
				outline: none;
			}
			.secondaryButton:active {
				box-shadow: 0px 1px 2px rgba(0,0,0,1);
				color: ${theme.colors.base};
			}
			.actionButton,
			.actionButton:visited {
				align-items: center;
				background-color: ${theme.colors.successDark};
				border: 1px solid ${theme.colors.successLight};
				border-radius: ${theme.borderRadius};
				box-shadow: 0px 3px 5px rgba(0,0,0,0.95);
				color: ${theme.colors.white};
				flex-direction: row;
				font-weight: bold;
				height: 30px;
				justify-content: center;
				line-height: 30px;
				padding-left: calc(10px + 0.6vw);
				padding-right: 1vw;
				text-decoration: none;
				width: auto;
			}
			.actionButton:hover {
				box-shadow: 0px 3px 6px rgba(0,0,0,1);
				color: ${theme.colors.successLight};
				outline: none;
			}
			.actionButton:active {
				box-shadow: 0px 1px 2px rgba(0,0,0,1);
			}
			@media screen and (max-width: ${theme.responsive.phone}) {
				.nophone {
					display: none;
				}
			}
			@media screen and (max-width: ${theme.responsive.tiny}) {
				.notiny {
					display: none;
				}
			}
			`}
		</style>
		// https://stackoverflow.com/a/26245056  source of ideas for ol, li styles for decimal-numbering of numbered lists.

	);
};

export default SharedStyles;
