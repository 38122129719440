import React from "react";
import theme from "./helpers/theme";
import landing_image from "./images/house_plan_visinvo_noblue.png";
import blueprint_square from "./images/blueprint_square.png";

const MainNightDay = () => {
  return (
    <>
      <style>{`
        .landingSectionBody {
          --sectionHeight: calc(200px + 40vw);
        }
        .sectionBody.landingSectionBody {
          background-image: url(${blueprint_square});
          position: relative;
        }
        .contentWidth.landingSectionInner {
          flex-grow: 1;
          justify-content: center;
          position: relative;
          height: 100%;
          width: 100%;
          height: var(--sectionHeight);
          max-height: 800px;
        }
        .landingSectionBg {
          background-image: url(${landing_image});
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 60%;
          overflow: hidden;
          position: absolute;
          width: 100%;
          z-index: 1;
        }
        @media screen and (min-width: ${theme.responsive.screenLarge}) {
          .landingSectionBg {
            height: 500px;
          }
        }
        @media screen and (max-width: ${theme.responsive.phone}) {
          .landingSectionBg {
            width: calc(100% - 50px);
          }
          .contentWidth.landingSectionInner {
            padding: 0 25px;
          }
        }
        .landingSection {
          height: 100%;
          position: relative;
          width: 100%;
          z-index: 2;
        }
        .s1_title1 {
          color: ${theme.colors.baseMedium};
          display: block;
          font-size: calc(10px + 1vw);
          line-height: 1.2em;
          margin-top: 2%;
          text-align: center;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.99);
          width: 100%;
        }
        .s1_title2 {
          color: ${theme.colors.baseMedium};
          display: block;
          font-size: calc(20px + 1vw);
          line-height: 1.2em;
          margin-top: 0%;
          text-align: center;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.99);
          width: 100%;
        }
        @media screen and (min-width: ${theme.responsive.screenLarge}) {
          .s1_title1 {
            font-size: 34px;
          }
          .s1_title2 {
            font-size: 44px;
          }
        }
        .s1_title3 {
          color: ${theme.colors.accentHover};
          display: block;
          font-size: calc(14px + 1vw);
          left: 0;
          line-height: 1.2em;
          top: 82%;
          position: absolute;
          text-align: center;
          text-shadow: 1px 1px 2px rgba(0,0,0,0.99);
          width: 100%;
        }
        @media screen and (min-width: ${theme.responsive.screenLarge}) {
          .s1_title3 {
            font-size: 30px;
          }
        }
        @media screen and (max-width: ${theme.responsive.phone}) {
          .s1_title1 {
            margin-top: 4%;
          }
          .s1_title3 {
            top: calc(59% + 15vw);
          }
        }
        @media screen and (max-width: ${theme.responsive.tiny}) {
          .s1_title3 {
            font-size: calc(12px + 1vw);
          }
        }
      `}</style>
      <div className="sectionBody landingSectionBody">
        <div className="contentWidth landingSectionInner">
          <div className="landingSectionBg"></div>
          <div className="landingSection">
            <div className="s1_title1">Residential & Commercial</div>
            <div className="s1_title2">Pre-Construction Management System</div>
            {/* <div className="s1_title3">BG Image - fading screenshot into house plan fading into house stock image</div> */}
            <div className="s1_title3">
              Your best time-saving choice.<br />
              Optimizing your success step by step.<br />
              Your partner increasing your profitability.
            </div>
          </div>
        </div>
      </div>
    </>
  )
};

export default MainNightDay;
